'use strict';

import axios from 'axios';
import { save as saveSetting, getByKey } from '@/services/setting.service';

export default {
    async getUser({ commit, dispatch, state }) {
        commit('SET_ISLOADING', true);

        // Get user from LS.
        if (state.isOffline) {
            commit('SET_USER', await getByKey('user'));
            return;
        }

        const response = await axios.get('/api/User/GetLoggedIn');
        commit('SET_USER', response.data);
        await saveSetting('user', response.data);
        // Sets default customer if user only has permission to one AND if customer is not already selected.
        if (!getByKey('selectedCustomer') && response.data.defaultCustomer) {
            await saveSetting('selectedCustomer', response.data.defaultCustomer);
            commit('SET_CUSTOMER', response.data.defaultCustomer);
            // Get selected customer menu.
            dispatch('archive/getMenu', {}, { root: true });
        }
    },
    async setCustomer({ commit, dispatch }, customer) {
        await saveSetting('selectedCustomer', customer);
        commit('SET_CUSTOMER', customer);

        await saveSetting('selectedBuilding', null);
        commit('SET_BUILDING', null);
        // Get selected customer menu.
        dispatch('archive/getMenu', {}, { root: true });
    },
    async setBuilding({ commit, dispatch }, building) {
        await saveSetting('selectedBuilding', building);
        commit('SET_BUILDING', building);
        // Get selected customer menu.
        dispatch('archive/getMenu', {}, { root: true });
    },
    async setUserOrganization({ commit, dispatch }, organizationId) {
        await axios.post(`/api/User/SetUserOrganization/${organizationId}`);
        commit('SET_USER_ORGANIZATION', organizationId);
        // Clear customer.
        await saveSetting('selectedCustomer', null);
        commit('SET_CUSTOMER', null);
        // Get user.
        dispatch('user/getUser', {}, { root: true });
        // Get selected customer menu.
        dispatch('archive/getMenu', {}, { root: true });
    },
    async setOffline({ commit }, val) {
        await saveSetting('isOffline', val);
        commit('SET_IS_OFFLINE', val);
    },
    async toggleOfflineMode({ commit, state, dispatch }) {
        const newValue = !state.isOffline;
        await saveSetting('isOffline', newValue);
        if (!newValue) {
            // Get user.
            dispatch('user/getUser', {}, { root: true });

            // Get selected customer menu.
            dispatch('archive/getMenu', {}, { root: true });
        }

        commit('SET_IS_OFFLINE', newValue);
    }
};