'use strict';

import actions from '@/store/modules/ArchiveModule/actions';
import mutations from '@/store/modules/ArchiveModule/mutations';

export const archiveModule = {
    namespaced: true,
    state: {
        menu: [],
        isLoading: true,
        types: [
            { value: 2, text: 'Rubrik', icon: 'registered' },
            { value: 3, text: 'Behållare', icon: 'folder-open' },
            { value: 0, text: 'Artikel', icon: 'file-edit' },
            { value: 4, text: 'Intern länk', icon: 'link' },
            { value: 5, text: 'Extern länk', icon: 'external-link' },
            { value: 6, text: 'Global länk', icon: 'global-link' }
            //{ value: 1, text: 'Dokumentlista' },
        ],
        customerAdminMenu: [
            {
              component: {
                    render(h) {
                        return h('div', { class: 'menu-divider' });
                    }
              }
            },
            {
                title: 'Inställningar',
                icon: {
                    element: 'font-awesome-icon',
                    attributes: {
                        icon: 'cog'
                    }
                },
                child: [
                    {
                        title: 'Användare',
                        href: '/admin/manage/users',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'user'
                            }
                        }
                    },
                    //{
                    //    title: 'Innehåll',
                    //    href: '/admin/manage/archives',
                    //    icon: {
                    //        element: 'font-awesome-icon',
                    //        attributes: {
                    //            icon: 'sitemap'
                    //        }
                    //    }
                    //}
                ]
            }
        ],
        adminMenu: [
            /* {
                header: true,
                title: 'Admin',
                hiddenOnCollapse: true
            },*/
            {
                component: {
                    render(h) {
                        return h('div', { class: 'menu-divider' });
                    }
                }
            },
            {
                title: 'Inställningar',
                icon: {
                    element: 'font-awesome-icon',
                    attributes: {
                        icon: 'cog'
                    }
                },
                child: [
                    {
                        title: 'Användare',
                        href: '/admin/manage/users',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'user'
                            }
                        }
                    },
                    {
                        title: 'Kunder',
                        href: '/admin/manage/customers',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'address-book'
                            }
                        }
                    },
                    {
                        title: 'Innehåll',
                        href: '/admin/manage/archives',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'sitemap'
                            }
                        }
                    }
                ]
            }
        ],
        superAdminMenu: [
            {
                component: {
                    render(h) {
                        return h('div', { class: 'menu-divider' });
                    }
                }
            },
            {
                title: 'Inställningar',
                icon: {
                    element: 'font-awesome-icon',
                    attributes: {
                        icon: 'cog'
                    }
                },
                child: [
                    {
                        title: 'Organisationer',
                        href: '/admin/manage/organizations',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'user-hard-hat'
                            }
                        }
                    },
                    {
                        title: 'Användare',
                        href: '/admin/manage/users',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'user'
                            }
                        }
                    },
                    {
                        title: 'Kunder',
                        href: '/admin/manage/customers',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'address-book'
                            }
                        }
                    },
                    {
                        title: 'Innehåll',
                        href: '/admin/manage/archives',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'sitemap'
                            }
                        }
                    },
                    {
                        title: 'Artiklar',
                        href: '/admin/manage/articles',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'box-open'
                            }
                        },
                        child: [
                            {
                                href: '/admin/manage/files/7',
                                title: 'Symboler'
                            }
                        ]
                    },
                    {
                        title: 'Roller',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'users'
                            }
                        },
                        child: [
                            {
                                href: '/admin/manage/roles',
                                title: 'Visa alla'
                            },
                            {
                                href: '/admin/manage/roles/save',
                                title: 'Skapa ny'
                            }
                        ]
                    },
                    {
                        title: 'Startsida',
                        icon: {
                            element: 'font-awesome-icon',
                            attributes: {
                                icon: 'tachometer-fast'
                            }
                        },
                        child: [
                            {
                                title: 'Dashboards',
                                href: '/admin/manage/dashboards',
                                icon: {
                                    element: 'font-awesome-icon',
                                    attributes: {
                                        icon: 'chart-network'
                                    }
                                }
                            },
                            {
                                title: 'Widgets',
                                href: '/admin/manage/widgets',
                                 icon: {
                                    element: 'font-awesome-icon',
                                    attributes: {
                                        icon: 'chart-line'
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    actions,
    mutations
};
