'use strict';

export const getCoordinateTypeAsNumber = function (coordinateTypeAsString) {
    switch (coordinateTypeAsString) {
        case 'all':
            return -1;
        case 'coordinates':
            return 0;
        case 'coordinatesInfo':
            return 1;
    }
};

export const getNumberFromCocatenatedNumbers = function (numbers, direction) {
    if (numbers.indexOf(',') === -1 && numbers.indexOf('.') === -1) {
        if (direction === 'after') return null;
        else return parseInt(numbers);
    }

    var splitted = numbers.split(',').length === 0 || numbers.split('.');
    switch (direction) {
        case 'before':
            return parseInt(splitted[0]);
        case 'after':
            return parseInt(splitted[1]);
    }
};

export const setBuildingPartPositionCoordinatesFromLowestPositionNumber =
    function (position, arr) {
        //if ((position.coordinateX || position.coordinateY) || (position.coordinateInfoX || position.coordinateInfoY))
        //    return;

        var positionWithLowestNumber = arr
            .filter((x) => x.number === position.number && x.id !== position.id)
            .sort((a, b) => {
                return (
                    parseFloat(a.concatenatedNumbers) - parseFloat(b.concatenatedNumbers)
                );
            });
        if (!positionWithLowestNumber[0]) return;

        // Set values.
        if (!position.coordinateX || !position.coordinateY) {
            position.coordinateX = positionWithLowestNumber[0].coordinateX;
            position.coordinateY = positionWithLowestNumber[0].coordinateY;
        }
        if (!position.coordinateInfoX || !position.coordinateInfoY) {
            position.coordinateInfoX = positionWithLowestNumber[0].coordinateInfoX;
            position.coordinateInfoY = positionWithLowestNumber[0].coordinateInfoY;
        }
    };

/**
 * Normalizes the values in an object of positions to ensure they stay within the bounds of a box.
 * 
 * This function iterates over the properties of the input object and 
 * replaces any values that are negative or exceed the maximum allowed 
 * dimensions of the box with the appropriate boundary values.
 *
 * @param {Object} positions - An object where the keys are position names and the values are numbers.
 * @param {Object} boxSize - An object containing the maximum width and height of the box.
 * @returns {Object} A new object with the same keys as the input, but with all values constrained within the box dimensions.
 *
 * @example
 * const positions = { x: -5, y: 10, z: 150 };
 * const boxSize = { width: 100, height: 100 };
 * const normalized = normalizePositions(positions, boxSize);
 * // normalized = { x: 0, y: 10, z: 100 };
 */
export const normalizePositionsInBox = function (positions, boxSize) {
    return Object.fromEntries(
        Object.entries(positions).map(([key, value]) => {
            if (key.toLowerCase().includes('x')) {
                return [key, Math.max(0, Math.min(boxSize.width, value))];
            } else if (key.toLowerCase().includes('y')) {
                return [key, Math.max(0, Math.min(boxSize.height, value))];
            }
            return [key, value];
        })
    );
};

export const getNewPositionsInBox = function (
    offsets,
    coordinateX,
    coordinateY,
    coordinateInfoX,
    coordinateInfoY,
    boxRectangle,
    scale,
    boxSize
) {
    const positions = {
        coordinateX: parseInt(
            ((offsets.x * scale.scale) / boxRectangle.width) * boxSize.width - coordinateX
        ),
        coordinateY: parseInt(
            ((offsets.y * scale.scale) / boxRectangle.height) * boxSize.height - coordinateY
        ),
        coordinateInfoX: parseInt(
            ((offsets.x * scale.scale) / boxRectangle.width) * boxSize.width - coordinateInfoX
        ),
        coordinateInfoY: parseInt(
            ((offsets.y * scale.scale) / boxRectangle.height) * boxSize.height - coordinateInfoY
        )
    };
    return positions;
    // Normalisera positionerna inom boxens dimensioner
    const normalizedPositions = normalizePositionsInBox(positions, boxSize);

    return normalizedPositions;
};

export const getPositionDependingOnEventType = function (event, panZoomScale, boxLeft, boxTop) {
    let xy = {
        x: event.offsetX,
        y: event.offsetY
    };

    // Touches.
    if (event.type === 'touchmove' || event.type === 'touchstart') {
        return {
            x: (event.touches[0].clientX - boxLeft) / panZoomScale.scale,
            y: (event.touches[0].clientY - boxTop) / panZoomScale.scale
        };
    }

    if (event.type === 'touchend') {
        return {
            x: (event.changedTouches[0].clientX - boxLeft) / panZoomScale.scale,
            y: (event.changedTouches[0].clientY - boxTop) / panZoomScale.scale
        };
    }

    return xy;
};