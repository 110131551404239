'use strict';

import Vue from 'vue';
import Vuex from 'vuex';

import { userModule } from '@/store/modules/UserModule/index';
import { archiveModule } from '@/store/modules/ArchiveModule/index';
import { buildingPositionsModule } from '@/store/modules/BuildingPositionsModule/index';
import { controlModule } from '@/store/modules/ControlModule/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user: userModule,
    archive: archiveModule,
    buildingPositions: buildingPositionsModule,
    control: controlModule
  }
});

export default store;