<template>
  <div v-if="!user.isLoading" class="p-1">
    <div class="text-white text-center">
      <a v-if="user.selectedCustomer === null" href="#" @click="viewChange()">
        Välj kund
      </a>
      <a
        v-else-if="user.selectedCustomer !== null"
        v-b-tooltip.hover
        href="#"
        title="Byt kund"
        @click="viewChange()"
      >
        {{ user.selectedCustomer.name }}
      </a>
      <b-modal
        :id="`modal-customer-${_uid}`"
        title="Byt kund"
        ok-title="Byt"
        cancel-title="Avbryt"
        @ok="changeCustomer" 
        no-close-on-backdrop
      >
        <label>Sök eller välj kund</label>
        <v-select
          ref="customer-select-list"
          v-model="newCustomer"
          placement="top"
          :filterable="false"
          :options="customers"
          :clearable="false"
          @search="onSearch"
          @option:selected="onSelect"
          @option:blur="onBlur"
          @click.stop=""
          v-on:keyup.stop=""
        >
          <template slot="no-options">{{isSuperAdmin ? 'Sök' : 'Välj'}} </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.name }} ({{ option.customerNo }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} ({{ option.customerNo }})
            </div>
          </template>
        </v-select>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { get } from '@/helpers/api';
import { debounce } from '@/helpers/input.helper';

export default {
  name: 'CustomerSelectList',
  data() {
    return {
      isChanging: false,
      allCustomers: [],
      customers: [],
      selected: null,
      newCustomer: null
    };
  },
  computed: {
    ...mapGetters('user', ['isInRole']),
    ...mapState({
      user: (state) => state.user,
      userIsLoading: (state) => state.user.isLoading
    }),
    isSuperAdmin() {
      return this.isInRole('SuperAdmin');
    }
  },
  watch: {
    userIsLoading: {
      handler(newValue) {
        if (!newValue) {
          if (!this.isSuperAdmin) {
            get('Customer', 'GetAllAvailableForUser').then((x) => {
              this.allCustomers = x.data;
              this.customers = x.data;
            });
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Change title.
    if (this.user.selectedCustomer !== null)
      document.title = this.user.selectedCustomer.name + ' - ' + process.env.VUE_APP_TITLE;
  },
  methods: {
    ...mapActions('user', ['setCustomer']),
    onSearch: debounce(
      function (value) {
        const trimmed = value.trim();
        if (trimmed.length > 1) this.searchCustomers(trimmed);
      },
      250,
      false
    ),
    async searchCustomers(query) {
      // Search with api or filters allCustomers.
      if (this.isSuperAdmin)
        await get(
          'Customer',
          `GetAvailableCustomersForUserByQuery?query=${encodeURIComponent(query)}`
        ).then((x) => (this.customers = x.data));
      else
        this.customers = this.allCustomers.filter(
          (x) =>
            x.customerNo.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            x.name.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    },
    onSelect(item) {
      this.newCustomer = item;
      // Set select lists customers to all customers.
      if (!this.isSuperAdmin) this.customers = this.allCustomers;

      this.isChanging = false;
    },
    onBlur() {
      this.isChanging = false;
    },
    viewChange() {
      this.isChanging = true;
      this.newCustomer = this.user.selectedCustomer;
      this.$bvModal.show(`modal-customer-${this._uid}`);
    },
    changeCustomer() {
      this.setCustomer(this.newCustomer);
      this.newCustomer = null;
    }
  }
};
</script>

<style scoped>
.d-center {
  display: flex;
  align-items: center;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}
</style>

<style>
.vs__dropdown-toggle {
  border-radius: 0px !important;
}
</style>
