import Vue from 'vue';
import { setBuildingPartPositionCoordinatesFromLowestPositionNumber } from '@/helpers/coordinate.helper';

export default {
  SET_BUILDING_PART(state, parts) {
    state.parts = parts;
    state.isLoadingParts = false;
  },
  SET_ACTIVE_PART_SYMBOL_SIZE(state, symbolSize) {
    state.activePartTab.symbolSize = symbolSize;
  },
  SET_BUILDING_PART_POSITIONS(state, partPositions) {
    state.partPositions = partPositions;
    state.isLoadingPartPositions = false;
  },
  SET_ACTIVE_PART_TAB(state, partId) {
    state.activePartTab =
      partId === null ? null : state.parts.find((x) => x.id === partId);
  },
  SET_IS_POSITION_NOT_IN_ANY_SCHEDULE(state, obj) {
    const existing = state.partPositions.find((x) => x.id === obj.id);
    existing.isPositionNotInAnySchedule = obj.isPositionNotInAnySchedule;
  },
  OPEN_PART_POSITION_SIDEBAR(state, partPosition) {
    state.partPositionSidebar.active = partPosition;
    state.partPositionSidebar.isOpen = partPosition !== null;
  },
  CLEAR_PART_POSITION_SIDEBAR(state) {
    state.partPositionSidebar.active = null;
  },
  OPEN_PART_SIDEBAR(state, part) {
    state.partSidebar.active = { ...part };
    state.partSidebar.isOpen = part !== null;
  },
  REMOVE_BUILDING_PART_POSITIONS(state, id) {
    const index = state.partPositions.findIndex((x) => x.id === id);
    state.partPositions.splice(index, 1);
  },
  SAVE_BUILDING_PART_POSITIONS(state, partPosition) {
    const index = state.partPositions.findIndex(
      (x) => x.id === partPosition.id
    );
    setBuildingPartPositionCoordinatesFromLowestPositionNumber(
      partPosition,
      state.partPositions
    );
    if (index >= 0) {
      Vue.set(state.partPositions, index, partPosition);
      state.partPositions.splice(index, 1, partPosition);
    } else state.partPositions.push(partPosition);
  },
  SAVE_BUILDING_PART(state, part) {
    const index = state.parts.findIndex((x) => x.id === part.id);
    if (index >= 0) {
      Vue.set(state.parts, index, part);
      state.parts.splice(index, 1, part);

      // Is active tab. Replaces.
      if (state.activePartTab.id === part.id)
        state.activePartTab = state.parts.find((x) => x.id === part.id);
    } else state.parts.push(part);
  },
  DELETE_BUILDING_PART(state, partId) {
    const index = state.parts.findIndex((x) => x.id === partId);
      state.parts.splice(index, 1);

    // If active tab, set to null.
    if (state.activePartTab != null) {
        if (state.activePartTab.id === partId)
            state.activePartTab = null;
    }
  },
  /**
   * Saves coordinates depending on type.
   * @param {object} obj {id, x, y, type}
   */
  SET_PARTS_POSITION(state, obj) {
    const existing = state.partPositions.find((x) => x.id === obj.id);
    switch (obj.type) {
      case 'all':
        existing.coordinateX = obj.coordinateX;
        existing.coordinateY = obj.coordinateY;
        existing.coordinateInfoX = obj.coordinateInfoX;
        existing.coordinateInfoY = obj.coordinateInfoY;
        break;
      case 'coordinates':
        existing.coordinateX = obj.coordinateX;
        existing.coordinateY = obj.coordinateY;
        break;
      case 'coordinatesInfo':
        existing.coordinateInfoX = obj.coordinateInfoX;
        existing.coordinateInfoY = obj.coordinateInfoY;
        break;
    }
  },
    SET_PLACE_COORDINATES_ON_DRAWING(state, position) {
    state.drawing.activePosition = position;
  },
  ABORT_PLACE_POSITION_ON_DRAWING(state) {
    const existing = state.partPositions.find(
      (x) => x.id === state.drawing.activePosition.id
    );
    existing.coordinateX = null;
    existing.coordinateY = null;
    existing.coordinateInfoX = null;
    existing.coordinateInfoY = null;

    state.drawing.activePosition = null;
  },
  CLEAR_POSITION_COORDINATE(state, positionId) {
    const existing = state.partPositions.find(
      (x) => x.id === positionId
    );
    existing.coordinateX = null;
    existing.coordinateY = null;
    existing.coordinateInfoX = null;
    existing.coordinateInfoY = null;
  },
};
