'use strict';

import actions from '@/store/modules/UserModule/actions';
import mutations from '@/store/modules/UserModule/mutations';
import getters from '@/store/modules/UserModule/getters';
import { getByKey } from '@/services/setting.service';

export const userModule = {
    namespaced: true,
    state: {
        user: getByKey('user'),
        isLoading: true,
        selectedCustomer: getByKey('selectedCustomer'),
        selectedBuilding: getByKey('selectedBuilding'),
        isOffline: getByKey('isOffline')
    },
    actions,
    mutations,
    getters
};