'use strict';

import Vue from 'vue';
import { getIcon, formatBytes } from '@/helpers/file.helper';

Vue.filter('formatFileSize', function (value) {
  return formatBytes(value);
});

Vue.filter('getIcon', function (value) {
  return getIcon(value);
});
