'use strict';

import actions from '@/store/modules/BuildingPositionsModule/actions';
import mutations from '@/store/modules/BuildingPositionsModule/mutations';
import getters from '@/store/modules/BuildingPositionsModule/getters';

export const buildingPositionsModule = {
  namespaced: true,
  state: {
    activePartTab: null,
    // Parts.
    isLoadingParts: true,
    parts: [],
    // Part positions.
    isLoadingPartPositions: true,
    partPositions: [],
    activePartPosition: null,
    partSidebar: {
      isOpen: false,
      active: null
    },
    partPositionSidebar: {
      isOpen: false,
      active: null
    },
    drawing: {
      activePosition: null
    }
  },
  actions,
  mutations,
  getters
};
