'use strict';

import axios from 'axios';
import { service } from '@/services/do-control.service';
import { articleService } from '@/services/article.service';;

export default {
    async get({ commit }, id) {
        commit('SET_IS_LOADING', true);

        let control = await service.getFirst(id);
        commit('SET_ACTIVE_CONTROL', control || (await axios.get(`/api/Control/Get/${id}`)).data);
    },
    async fetchControlPartPositionById({ commit }, id) {
        const controlPartPosition = await service.fetchControlPartPositionById(id);
        commit('ADD_POSITION_TO_CONTROL', controlPartPosition);
    },
    async postPartPosition({ commit, state }, vm) {
        await service.setControlPartPosition(
            state.active.checkedOut,
            state.active.id,
            vm
        );
        commit('SET_CONTROL_PART_POSITION', vm);
    },
    async setNewRevisionDate({ commit, state }, vm) {
        const response = await service.setNewRevisionDate(
            state.active.checkedOut,
            state.active.id,
            vm.buildingPartPositionId,
            vm.years,
            //vm.nextRevisionDate
        );

        commit('SET_NEW_REVISION_DATE', {
            buildingPartPositionId: vm.buildingPartPositionId,
            newValue: response.data
        });

        return response.data;
    },
    async setAllPositionStatusesToDone({ commit, state }, partId) {
        const positionsWithNewStatus = await service.setAllPositionsToDone(state.active, partId);

        commit('SET_ALL_POSITION_STATUSES_TO_DONE', positionsWithNewStatus);
    },
    async checkOut({ commit, state }) {
        const checkedOutProps = await service.checkOut(state.active);
        await articleService.getAllArticlesForControl(state.active.id);
        commit('CHECK_OUT_CONTROL', checkedOutProps);
    },
    async setToDone({ commit, state }, vm) {
        await service.setToDone(state.active.id, vm.done, vm.controlNotes);
        commit('SET_TO_DONE', vm);
    },
    async setNotes({ commit, state }, controlNotes) {
        await service.setControlNotes(state.active.checkedOut, state.active.id, controlNotes);
        commit('SET_NOTES', controlNotes);
    },
    async abortCheckIn({ commit, state }) {
        await service.abortCheckIn(state.active.id);
        commit('ABORT_CHECK_IN');
    },
    async checkIn({ commit }, controlId) {
        commit('SET_IS_CHECKING_IN', true);
        await service.checkIn(controlId);
        commit('SET_IS_CHECKING_IN', false);
    },
    async checkInAll({ commit }) {
        commit('SET_IS_CHECKING_IN', true);
        const errors = await service.checkInAll();
        commit('ABORT_CHECK_IN');
        commit('SET_IS_CHECKING_IN', false);

        return errors;
    }
};